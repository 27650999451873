import { useRouter } from 'next/router';
import { Avatar } from '@mui/material';
import { Job } from 'lib/types/jobs';
import { imageAvatarUrl } from 'lib/utils/imageUtils';
import { formatLocalString } from 'lib/utils/datetime';
import { User } from 'lib/types/users';
import { buildJobPath } from 'lib/helpers';
import { A } from 'components/A';
import Link from 'next/link';
import { useApi } from 'lib/contexts/ApplicationState';
import { trackClickInternalApplicationEvent } from 'lib/hooks/useEventTracking';
import { ProfileLink } from 'components/ProfileLink';
import { FeaturedTag } from 'components/FeaturedTag';

interface Props {
  account: User;
  job: Job;
}

export const SimpleJobCard = ({
  account, job,
}: Props) => {
  const router = useRouter();
  const jobPath = buildJobPath(job);
  const API = useApi();

  const click = async () => {
    if (!job.externalLink) {
      // track internal apply
      await trackClickInternalApplicationEvent(API, job.id);
    }
    router.push(`${jobPath}?apply=1`);
  };

  const getApplyButton = () => {
    if (job.appliedAt) {
      return (
        <>
          <span className="bg-lightgray px-4 py-2 text-sm font-semibold rounded-md">
            Applied on
            {' '}
            {formatLocalString(job.appliedAt)}
          </span>
        </>
      );
    }

    return <button type="button" className="btn-primary" onClick={click}>Apply</button>;
  };

  return (
    <div className={`relative w-full h-full border rounded-lg px-6 pt-10 pb-16 ${job.isFeatured ? 'border-paleyellow border-4' : ''}`}>
      {job.isFeatured && (
        <div className="absolute top-0 left-0">
          <FeaturedTag
            title="Gondola for Teams members have their jobs randomly featured at the top of jobs searches where the roles match the search criteria, as well as on the homepage"
            upgradeTitle="Gondola for Teams members have their jobs randomly featured at the top of jobs searches. Sign up for TEAMS to join them!"
            styling="absolute -top-0.5 -left-0.5"
            teamsFeature
          />
        </div>
      )}
      <div>
        <div className="flex">
          <div className="w-1/3">
            <Avatar
              sx={{ width: 50, height: 50, margin: 'auto' }}
              variant="circular"
              alt={account.name}
              src={imageAvatarUrl(account.avatarUrl, 100)}
            />
          </div>
          <div className="w-2/3">
            <div className="text-sm">
              @
              {account.username}
            </div>
            <div className="text-lg font-semibold mb-4 hover:underline">
              <ProfileLink
                user={account}
                username={account.username}
                linkToExplore={account.isHidden}
                context="job-card"
              >
                {account.name}
              </ProfileLink>
            </div>
          </div>
        </div>
        <Link href={buildJobPath(job)} className="hover:underline">
          <div className="text-xl font-bold mb-2">{job.name}</div>
        </Link>
      </div>
      <div className="absolute bottom-4">
        <div className="flex flex-wrap gap-2">
          <A href={jobPath} className="btn-primary-outlined">View</A>
          {getApplyButton()}
        </div>
      </div>
    </div>
  );
};
