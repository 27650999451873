import { useState } from 'react';
import { MenuItem, Switch } from '@mui/material';
import { useRouter } from 'next/router';
import { Job } from 'lib/types/jobs';
import { buildJobPath } from 'lib/helpers';
import { useApi } from 'lib/contexts/ApplicationState';
import { DropdownMenu } from 'components/DropdownMenu';
import { SaveToProjectListButton } from 'components/SaveToList';
import { useSnackbar } from 'notistack';

interface Props {
  job: Job;
}

export const JobCardMenu = ({
  job,
}: Props) => {
  const API = useApi();
  const [isFeatured, setIsFeatured] = useState(job.isFeatured);
  const router = useRouter();
  const jobPath = buildJobPath(job);
  const { enqueueSnackbar } = useSnackbar();

  const toggleIsFeatured = async () => {
    setIsFeatured(!isFeatured);
    await API.updateJob(job.id, { isFeatured: !isFeatured, teamId: job.teamId });
  };

  const handleClose = async () => {
    try {
      await API.updateJob(job.id, { status: 'closed', teamId: job.teamId });
      enqueueSnackbar('Job closed', {
        variant: 'success',
      });
      router.reload();
    } catch (err) {
      enqueueSnackbar(`Error closing job: ${err}`, {
        variant: 'error',
      });
    }
  };

  const goTo = (href: string) => {
    router.push(href);
  };

  return (
    <DropdownMenu>
      <MenuItem onClick={() => goTo(jobPath)}>
        View job details
      </MenuItem>
      <MenuItem onClick={toggleIsFeatured}>
        Feature job
        <Switch
          checked={isFeatured}
          name="isJobFeatured"
          color="primary"
        />
      </MenuItem>
      <MenuItem>
        <SaveToProjectListButton itemId={job.id} teamId={job.teamId} itemType="Job" context="list" text="Save to project list" />
      </MenuItem>
      <MenuItem onClick={handleClose} disabled={job.status === 'closed'}>
        Close job
      </MenuItem>
    </DropdownMenu>
  );
};
