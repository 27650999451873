import { DateTime } from 'luxon';
/**
 * Return a date numMonths months before the current date
 * @param {number} numMonths - The number of months ago
 */
export const monthsAgo = (numMonths: number) => {
  const now = new Date();
  now.setMonth(now.getMonth() - numMonths);

  return now;
};

/**
 * Return a date numDays days before the current date
 * @param {number} numDays - The number of days ago
 */
export const daysAgo = (numDays: number) => {
  const now = new Date();
  now.setDate(now.getDate() - numDays);

  return now;
};

/**
 * Returns the number of days between two dates
 */
export const daysDiff = (date1: string, date2: string) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  const diff = Math.abs(d2.getTime() - d1.getTime());
  return Math.round(diff / (1000 * 60 * 60 * 24));
};

/**
 * Convert a date to yyyy-mm-dd format
 * @param {Date} date - The date
 */
export const yyyymmdd = (date: Date) => date.toISOString().split('T')[0];

/** toDateTime converts a date that may be a string, Date, or DateTime to a luxon DateTime.
 * 
 * This is necessary because of differences between client-side and server-side rendering:
 * a server-side render may be dealing with a DateTime as expected, but a client-side
 * render may have the serialized version of the date, which is just an ISO string.
 * @param  {string|DateTime|Date} date
 * @returns DateTime
 */
export const toDateTime = (date: string | DateTime | Date): DateTime => {
  if (typeof date === 'string') {
    return DateTime.fromISO(date);
  } if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  }

  return date as DateTime;
};

// the job start date is a day off since we don't save time, so need to set UTC
export const formatLocalStringUTC = (date: string | Date) => {
  if (!date) return '';

  return new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' });
};

export const formatLocalString = (date: string | Date) => {
  if (!date) return '';

  return new Date(date).toLocaleDateString();
};

export const convertZuluDate = (zuluDate?: string | Date) => (zuluDate
  ? (new Date(zuluDate)).toLocaleDateString()
  : null);

export const convertLocalTime = (zuluDate?: string | Date) => (zuluDate
  ? (new Date(zuluDate)).toLocaleString()
  : null);

export const convertLocalDate = (zuluDate?: string | Date) => (zuluDate
  ? (new Date(zuluDate)).toLocaleDateString()
  : null);
